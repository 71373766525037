@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
    .animation-delay-2000 {
        animation-delay: 2s;
    }
    .animation-delay-4000 {
        animation-delay: 4s;
    }
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
ul,
li,
* {
    padding: 0;
    margin: 0;
    font-family: 'InterTight', -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.card-body p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.card-body h2 {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.text-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.read-more {
    /* display: none; */
}

.overflowed + .read-more {
    display: inline;
}

@font-face {
    font-family: 'InterTight';
    src: url('./assets/inter-tight-font/InterTight-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'InterTight';
    src: url('./assets/inter-tight-font/InterTight-SemiBold.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'InterTight';
    src: url('./assets/inter-tight-font/InterTight-Bold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'InterTight';
    src: url('./assets/inter-tight-font/InterTight-Black.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'InterTight';
    src: url('./assets/inter-tight-font/InterTight-Light.ttf');
    font-weight: 300;
}

.tiptap code {
    background-color: rgba(97, 97, 97, 0.4);
    border-radius: 0.25em;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    color: #fafafa;
    font-size: 0.9rem;
    padding: 0.25em;
}

.tiptap * + * {
    margin-top: 0.1em;
}

.tiptap ul {
    padding: 0 1rem;
    list-style-type: disc;
    margin-left: 8px;
}
.tiptap ol {
    padding: 0 1rem;
    margin-left: 8px;
    list-style-type: decimal;
}

.tiptap h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}
.tiptap h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.bubble-menu {
    display: flex;
    background-color: #ffffff;
    border-radius: 6px;
    width: fit-content;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);

    button {
        border: none;
        background: none;
        color: #0d0d0d;
        font-size: 0.9rem;
        font-weight: 500;
        opacity: 0.8;
        padding: 0.1rem 0.5rem;
        margin: 2px;
        border-radius: 6px;

        &:hover,
        &.is-active {
            opacity: 1;
            background-color: #333333;
            color: #e2e8f0;
        }
    }
}

::placeholder {
    color: #adb5bded !important;
}

ul[data-type='taskList'] {
    list-style: none;
    padding: 0;
}

ul[data-type='taskList'] p {
    margin: 0;
}

ul[data-type='taskList'] li {
    display: flex;
}

ul[data-type='taskList'] li > label {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    user-select: none;
}

ul[data-type='taskList'] li > div {
    flex: 1 1 auto;
}

ul[data-type='taskList'] li ul li,
ul[data-type='taskList'] li ol li {
    display: list-item;
}

ul[data-type='taskList'] > li {
    display: flex;
}

.tiptap .is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}
.locked {
    pointer-events: none;
}

.image-fade {
    position: relative; /* Needed for proper positioning of the pseudo-element */
}

.image-fade::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px; /* Adjust the height of the fade as needed */
    background: linear-gradient(
        transparent,
        rgba(248, 248, 255, 1)
    ); /* Adjust the colors and transparency as needed */
    pointer-events: none; /* Prevent the gradient from blocking mouse events */
}
